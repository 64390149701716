
document.addEventListener("DOMContentLoaded", () => {
  const searchForm = document.querySelector("#search-form form");
  const searchButton = document.querySelector("#search-form button.btn-search");
  const searchField = document.querySelector('#search-form #search-field');
  searchField.value = '';

  if (searchButton) {
    searchButton.addEventListener("click", (event) => {
      event.preventDefault();
      if (searchField.classList.contains("active")) {
        searchField.classList.remove("active");
        searchField.blur();
      } else {
        searchField.classList.add("active");
        searchField.value = "";
        searchField.focus();
      }
    });

    searchField.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
		const url = new URL(searchForm.action, document.location);
		url.searchParams.append("q", event.target.value);
        window.location.href = url.toString();
      }
    });
  }

}, {once: true});
